<template>
  <div>
    <AtomsTextHeader :type="2" class="header--with-line">{{ props.header ?? $t('Náš blog') }}</AtomsTextHeader>
    <p class="mb-8 text-gray">{{ $t('Novinky, o kterých musíte vědět') }}</p>

    <div class="-mx-3">
      <carousel :breakpoints="carouselBreakpoints" :itemsToShow="1" :itemsToScroll="1">
        <slide v-for="item in articles" :key="slide">
          <MoleculesBlocksMagazineItem class="mx-3" :key="item.id" :title="item.page.title" :url="item.page.url" :image-url="`https://www.sperky4u.eu/img/magazine/${item.id}_cover.jpg`" :date="item.page.lastModifiedAt ? (item.created > item.page.lastModifiedAt ? item.created : item.page.lastModifiedAt) : item.created" :minutes-to-read="item.timeToRead" />
        </slide>
        <template #addons>
          <Pagination />
        </template>
      </carousel>
    </div>
  </div>
</template>
<script setup>

import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import "assets/css/carousel.css";

const props = defineProps({
  header: String
});

const locale = useLocale().getLocale();
const appConfig = useAppConfig();

const carouselBreakpoints = {
  600: {
    itemsToShow: 2,
    snapAlign: "left",
  },
  1280: {
    itemsToShow: 3,
    snapAlign: "left",
  },
};

const queryUrl = `/api/magazineArticles?take=6&skip=0&categoryIds=${appConfig.magazineCategoryIds}&currencyId=${locale.currencyId}&languageId=${locale.languageId}`;
const articles = (await useApiFetch(queryUrl)).data.value.infoPageList.infoPages.items;

</script>